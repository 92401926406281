<template>
<div>
	<el-dialog
	title="新建部门"
	:visible.sync="departmentDialog.visible"
	:before-close="departmentDialogClose"
	class="department-dialog">
		<el-form label-width="5.20833vw">
		<el-form-item label="部门名称：">
			<el-input placeholder=""></el-input>
		</el-form-item>

		<el-form-item label="所属组织：">
			<el-select placeholder="选择组织" value="" class="input-select">
			<el-option label="组织1" value="选项1"></el-option>
			<el-option label="组织2" value="选项2"></el-option>
			</el-select>
		</el-form-item>

		<el-form-item label="关联学校：">
			<el-select placeholder="选择学校" value="" class="input-select">
			<el-option label="学校1" value="选项1"></el-option>
			<el-option label="学校2" value="选项2"></el-option>
			</el-select>
		</el-form-item>

		<el-form-item label="排序：">
			<el-input placeholder=""></el-input>
		</el-form-item>

		<el-form-item label="部门描述：">
			<el-input placeholder=""></el-input>
		</el-form-item>
		</el-form>

		<div slot="footer">
			<el-button @click="dialogVisible = false">取消</el-button>
			<el-button type="primary" @click="dialogVisible = false">确定</el-button>
		</div>
	</el-dialog>
</div>
</template>

<script>
export default {
	props: {
		departmentHandleData: Object,
	},
	data() {
		return {
			departmentDialog: {
				visible: false,
				rules: {

				}
			}
		}
	},
	methods: {
		departmentDialogClose() {
		this.departmentDialog.visible = false;
		}
	},
	watch: {
		departmentHandleData() {
		this.departmentDialog.visible = true;
		}
	}
}
</script>

<style lang="scss" scoped>
.department-dialog {
	/deep/.el-dialog {
		.el-dialog__body {
		.input-select {
			width: 100%;
		}
		}
	}
}
</style>
