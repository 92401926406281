<template>
	<div class="course-wrapper">
		<div class="course-wrapper-title">
			<span>部门管理</span>
		</div>
		<div class="course-wrapper-body">
			<!-- 功能按钮 -->
			<div class="course-wrapper-function">
				<div class="function-left">
					<el-select placeholder="选择组织" value="" class="input-select">
						<el-option label="组织1" value="选项1"></el-option>
						<el-option label="组织2" value="选项2"></el-option>
					</el-select>

					<el-select placeholder="选择学校" value="" class="input-select">
						<el-option label="学校1" value="选项1"></el-option>
						<el-option label="学校2" value="选项2"></el-option>
					</el-select>

					<el-input class="input-search" placeholder="请输入部门名称" clearabel></el-input>

					<el-button type="primary" icon="el-icon-search">搜索</el-button>

					<el-button icon="el-icon-refresh-left">重置</el-button>
				</div>
				<div class="function-right">
					<el-button
						@click="handledePartmentButton('update')"
						icon="el-icon-refresh">同步</el-button>

					<el-button
						@click="handledePartmentButton('insert')"
						type="primary" icon="el-icon-plus">新建组织</el-button>
				</div>
			</div>

			<el-table :data="departmentTableData" border>
				<el-table-column label="ID" type="index" width="80" align="center"></el-table-column>

				<el-table-column prop="" label="部门名称"></el-table-column>

				<el-table-column prop="" label="编号"></el-table-column>

				<el-table-column prop="" label="所属组织"></el-table-column>

				<el-table-column prop="" label="成员数量"></el-table-column>

				<el-table-column prop="" label="排序" width="80" align="center"></el-table-column>

				<el-table-column prop="" label="状态" width="80" align="center">
          <el-switch active-color="#13ce66" inactive-color="#ff4949"></el-switch>
				</el-table-column>

				<el-table-column prop="" label="操作" class-name="table-handle" align="center" width="200">
					<template>
						<el-button type="text">编辑</el-button>
						<el-button type="text" class="button-danger">删除</el-button>
						<el-button type="text">子部门</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<department-dialog :departmentHandleData="departmentHandleData"></department-dialog>
	</div>
</template>

<script>
import departmentDialog from './departmentDialog.vue';
export default {
	name: 'departmentManage',
	components: {
    departmentDialog
	},
	data() {
		return {
			departmentTableData: [
				{},
				{},
			],
			departmentHandleData: {},
		}
	},
	methods: {
		handledePartmentButton(handleName) {
			switch (handleName) {
				case 'insert':
					this.departmentHandleData = {
						handle: 'insert',
					};
					break;

				default:
					break;
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/common_c.scss';
/deep/.course-wrapper-body {
	.course-wrapper-function {
		.function-left {
      .input-select,
			.input-search {
				margin-right: 10px;
			}

      .input-select {
        .el-input__inner {
          width: 200px;
        }
      }
		}
	}
}
</style>
